// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class TherapistUsersPage extends GridPageAbstract {
    constructor(props) {
        super(props);

        this.Name = 'therapistUsers';
        this.Server = server.therapistUsers;
        this.searchFields = [
            'user.firstName',
            'user.lastName',
            'therapist.workCompany'
        ];

        this.CanAdd = ['admin'];
        this.CanUpdate = ['admin'];
        this.CanDelete = ['admin'];

        this.Head = [
            {
                id: 'userId',
                object: 'user',
                type: 'object',
                display: 'firstName+lastName',
                label: 'user',
                alignRight: false,
                cell: 'Select',
                from: 'users'
            },
            {
                id: 'therapistId',
                object: 'therapist',
                display: 'user, workCompany',
                label: 'therapist',
                alignRight: false,
                cell: 'Select',
                from: 'therapists'
            },
            {
                id: 'dateModified',
                label: 'dateModified',
                alignRight: false,
                cell: 'Date',
                disableEditor: true
            },
            {
                id: 'dateCreated',
                label: 'dateCreated',
                alignRight: false,
                cell: 'Date',
                disableEditor: true
            }
        ];
    }
}
