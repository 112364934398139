import {
    Grid,
    Alert,
    AlertTitle,
    Stack,
    Button,
    Link
} from '@mui/material';
import React from 'react';
import i18n from 'i18';
import { AppBox, AppTests, AppList } from 'sections/dashboard';
import LinkInviteCodeDialog from 'pages/dialogs/LinkInviteCodeDialog';
import server from 'server';
import { formatError } from 'utils/formatError';

export default class TherapistDashboardContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            data: props.data,
            users: null,
            userLinks: null,
            tests: null
        };
        this.refreshData = this.refreshData.bind(this);
    }

    componentDidMount() {
        this.intervalId = setInterval(this.refreshData, 60000);
        this.refreshData();
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    refreshData() {
        server.tests
            .getAllFiltered([
                [{ field: 'status', op: '=', value: 'result_received' }]
            ])
            .then((result) => {
                if (result.error !== 'OK') {
                    this.setState({ alertText: formatError(result) });
                } else {
                    this.setState({ tests: result.data });
                }
            });

        server.therapistUsers.getAll().then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                this.setState({
                    users: result.data.map((item) => {
                        return {
                            id: item.user.id,
                            title:
                                item.user.firstName + ' ' + item.user.lastName
                        };
                    }),
                    userLinks: result.data.map((item) => {
                        return {
                            id: item.id,
                            title:
                                item.user.firstName + ' ' + item.user.lastName
                        };
                    })
                });
            }
        });
    }

    handleDeleteUser = async (id) => {
        server.therapistUsers.delete([id]).then((result) => {
            if (result.error !== 'OK') {
                this.setState({ alertText: formatError(result) });
            } else {
                this.refreshData();
            }
        });
    };

    render() {
        return (
            <Grid container spacing={3} alignItems="stretch">
                <LinkInviteCodeDialog
                    dialogOpen={this.state.linkInviteCodeDialogOpen}
                    onClose={() => {
                        this.setState({ linkInviteCodeDialogOpen: false });
                        this.refreshData();
                    }}
                />

                {this.state.alertText && (
                    <Grid item xs={12} sm={12} md={12}>
                        <Alert key="alert" severity="error">
                            <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                            {this.state.alertText}
                        </Alert>
                    </Grid>
                )}

                <Grid item xs={12} sm={6} md={4}>
                    <AppBox
                        title={i18n.t('dashboard.linkByCode')}
                        subheader={i18n.t('dashboard.linkByCodeSubheader')}
                    >
                        <Stack direction="column">
                            <Button
                                variant="contained"
                                onClick={() =>
                                    this.setState({
                                        linkInviteCodeDialogOpen: true
                                    })
                                }
                            >
                                {i18n.t('buttons.linkByCode')}
                            </Button>
                        </Stack>
                    </AppBox>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AppBox
                        title={i18n.t('dashboard.promoteInfo')}
                        subheader={i18n.t('dashboard.promoteInfoSubheader')}
                        styles={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Link
                            href="https://fims-medizin.ch/tap-diagnostik/"
                            target="_blank"
                            underline="hover"
                        >
                            <div>
                                <img
                                    src="/assets/images/promoteImage.png"
                                    alt="promoteImg"
                                    style={{
                                        // maxHeight: '180px',
                                        cursor: 'pointer',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '12px'
                                    }}
                                />
                            </div>
                        </Link>
                    </AppBox>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <AppBox
                        subtitleWithLink={i18n.t('dashboard.therapistWarningMessage')}
                        link="myhealth.kingnature.ch"
                        styles={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'flex-start',
                            flexWrap: 'wrap'
                        }}
                    >
                        <Link
                            href="https://vimeo.com/919663644/e45921f943?share=copy"
                            target="_blank"
                            underline="hover"
                        >
                            <div>
                                <img
                                    src="/assets/images/thumbnail_qrcode.png"
                                    alt="promoteImg"
                                    style={{
                                        // maxHeight: '180px',
                                        height: '100%',
                                        cursor: 'pointer',
                                        width: '100%',
                                        borderRadius: '12px',
                                        textAlign: 'center'
                                    }}
                                />
                            </div>
                        </Link>
                    </AppBox>
                </Grid>

                <Grid item xs={12} md={8} lg={8}>
                    <AppTests
                        title={i18n.t('dashboard.lastTests')}
                        subheader={i18n.t('dashboard.lastTestsSubheader')}
                        showUsers={true}
                        showPdf={true}
                        users={this.state.users}
                        data={this.state.tests}
                    />
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <AppList
                        onItemDelete={this.handleDeleteUser}
                        title={i18n.t('dashboard.linkedUsers')}
                        subheader={i18n.t('dashboard.linkedUsersSubheader')}
                        data={this.state.userLinks}
                        confirmDeleteText={i18n.t(
                            'dialogs.confirmDeleteOfLinkedUser'
                        )}
                    />
                </Grid>
            </Grid>
        );
    }
}
