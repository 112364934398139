export const parseValue = (value) => {
    let parsedValue;
    try {
        parsedValue = JSON.parse(value);
        if (typeof parsedValue !== 'object' || parsedValue === null) {
            throw new Error('Invalid format, expected object');
        }
    } catch (e) {
        parsedValue = {
            en: value || '',
            de: value || ''
        };
    }
    return parsedValue;
};
export const parseRichTextValue = (value) => {
    let parsedValue;
    try {
        parsedValue = JSON.parse(value);
        if (
            typeof parsedValue === 'object' &&
            parsedValue !== null &&
            parsedValue.hasOwnProperty('en') &&
            parsedValue.hasOwnProperty('de')
        ) {
            return parsedValue;
        }
        if (
            parsedValue &&
            Array.isArray(parsedValue) &&
            parsedValue.length > 0 &&
            parsedValue[0].hasOwnProperty('type') &&
            parsedValue[0].hasOwnProperty('children')
        ) {
            parsedValue = {
                en: value,
                de: value
            };
        }
    } catch (e) {
        console.warn(
            'Error parsing rich text value, fallback to localization format:',
            e
        );
        parsedValue = {
            en: value || '',
            de: value || ''
        };
    }
    if (!parsedValue) {
        parsedValue = {
            en: '',
            de: ''
        };
    }
    return parsedValue;
};
