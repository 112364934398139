import React from 'react';
import i18n from 'i18';
import { Box, TextField } from '@mui/material';
import RichText from '../../components/richtext';
import config from "../../config";

export default class DialogTranslatableField extends React.Component {
    handleChange = (fieldId, value) => {
        const currentLanguage = this.props.data.currentLanguage || i18n.language;
        let parsedValue;

        try {
            parsedValue = JSON.parse(this.props.value);
            if (typeof parsedValue !== 'object' || parsedValue === null) {
                throw new Error('Invalid format, expected object');
            }
        } catch (e) {
            parsedValue = {
                en: this.props.value || '',
                de: ''
            };
        }

        const newValue = { ...parsedValue, [currentLanguage]: value };
        this.props.onChange(fieldId, JSON.stringify(newValue));
    };

    render() {
        const currentLanguage = this.props.data.currentLanguage || i18n.language || config.language;
        let translatedName =
            i18n.t(`columns.${this.props.field.label}`) +
            ' (' +
            i18n.t(`enums.${currentLanguage}`) +
            ')';
        let parsedValue;

        try {
            parsedValue = JSON.parse(this.props.value);
            if (typeof parsedValue !== 'object' || parsedValue === null) {
                throw new Error('Invalid format, expected object');
            }
        } catch (e) {
            parsedValue = {
                en: this.props.value || '',
                de: this.props.value || ''
            };
        }

        const value = parsedValue[currentLanguage] || '';
        const fieldType = this.props.field.type;
        if (fieldType === 'richText') {
            return (
                <Box
                    sx={(theme) => ({
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: '6px',
                        mt: 1
                    })}
                >
                    <RichText
                        key={this.props.field.id + '-' + currentLanguage}
                        id={this.props.field.id}
                        label={translatedName}
                        value={value}
                        onChange={(val) =>
                            this.handleChange(this.props.field.id, val)
                        }
                    />
                </Box>
            );
        }

        return (
            <TextField
                autoFocus
                margin="dense"
                id={this.props.field.id}
                label={translatedName}
                type="text"
                value={value}
                fullWidth
                onChange={(event) =>
                    this.handleChange(event.target.id, event.target.value)
                }
            />
        );
    }
}
