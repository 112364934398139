import { Stack } from '@mui/material';

import MarkButton from './MarkButton';
import BlockButton from './BlockButton';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import CodeIcon from '@mui/icons-material/Code';

import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import LinkIcon from '@mui/icons-material/Link';

export default function Toolbar() {
    return (
        <Stack direction="row" spacing={0}>
            <MarkButton
                format="bold"
                icon={<FormatBoldIcon fontSize={'small'} />}
            />
            <MarkButton
                format="italic"
                icon={<FormatItalicIcon fontSize={'small'} />}
            />
            <MarkButton
                format="underline"
                icon={<FormatUnderlinedIcon fontSize={'small'} />}
            />
            <MarkButton format="link" icon={<LinkIcon fontSize={'small'} />} />
            <MarkButton format="code" icon={<CodeIcon fontSize={'small'} />} />
            <BlockButton
                format="heading-one"
                icon={<LooksOneIcon fontSize={'small'} />}
            />
            <BlockButton
                format="heading-two"
                icon={<LooksTwoIcon fontSize={'small'} />}
            />
            <BlockButton
                format="block-quote"
                icon={<FormatQuoteIcon fontSize={'small'} />}
            />
            <BlockButton
                format="numbered-list"
                icon={<FormatListNumberedIcon fontSize={'small'} />}
            />
            <BlockButton
                format="bulleted-list"
                icon={<FormatListBulletedIcon fontSize={'small'} />}
            />
            <BlockButton
                format="left"
                icon={<FormatAlignLeftIcon fontSize={'small'} />}
            />
            <BlockButton
                format="center"
                icon={<FormatAlignCenterIcon fontSize={'small'} />}
            />
            <BlockButton
                format="right"
                icon={<FormatAlignRightIcon fontSize={'small'} />}
            />
            <BlockButton
                format="justify"
                icon={<FormatAlignJustifyIcon fontSize={'small'} />}
            />
        </Stack>
    );
}
