import React from 'react';

import i18n from 'i18';
import RichText from 'components/richtext';
import { Box } from "@mui/material";

export default class DialogRichTextField extends React.Component {
    render() {
        // field, value, cache, onChange

        let translatedName = i18n.t(`columns.${this.props.field.label}`);

        return (
            <Box
                sx={(theme) => ({
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: '6px',
                    mt: 1
                })}
            >
                <RichText
                    id={this.props.field.id}
                    label={translatedName}
                    value={this.props.value || ''}
                    onChange={(value) =>
                        this.props.onChange(this.props.field.id, value)
                    }
                />
            </Box>
        );
    }
}
