export class GridDataAdapter {
    static transform(data, fields) {
        let transformedData = {};

        if (data && data.id) {
            transformedData.id = data.id;
        }
        const fieldProcessors = {
            object: (field) => {
                return data?.[field.object]?.id || null;
            },
            boolean: (field) => {
                return data?.[field.id] || false;
            },
            default: (field) => {
                return data?.[field.id] || null;
            }
        };
        fields
            .filter((f) => !f.disableEditor)
            .forEach((field) => {
                const processor = fieldProcessors[field.type] || fieldProcessors['default'];
                transformedData[field.id] = processor(field);
            });
        return transformedData;
    }
}
