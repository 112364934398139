import React from 'react';

import Fields from './editors';
import i18n from 'i18';

// @mui
import {
    Button,

    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,

    Alert,
    AlertTitle,
} from '@mui/material';
import { formatError } from 'utils/formatError';
import { LoadingButton } from '@mui/lab';


export default class DialogAbstract extends React.Component {

    onClose = () => { };
    onSave = () => { };

    Name;
    Head;
    cache;

    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: props.dialogOpen || false,
            dialogData: props.dialogData || {},
            alertText: null,
            loading: false,
        };

        this.Name = props.Name;
        this.Head = props.Head;
        this.Server = props.Server;
        this.cache = props.cache;

        this.onClose = props.onClose;
        this.onSave = props.onSave;
    }

    static getDerivedStateFromProps(props, state) {
        if (props.dialogOpen !== state.dialogOpen) {
            return {
                dialogOpen: props.dialogOpen,
                dialogData: props.dialogData || {},
                alertText: null,
            };
        }

        return null;
    }

    handleDialogCancel = (event, name) => {
        this.onClose();
    };

    handleDialogSave = async (event, name) => {
        let data = this.state.dialogData;

        if (Object.keys(data).includes('currentLanguage')) {
            delete data.currentLanguage;
        }

        this.setState({ loading: true });

        let result;

        if (data.id) {
            result = await this.Server.update(data.id, data);
        }
        else {
            result = await this.Server.add(data);
        }

        if (result.error === "OK") {

            this.setState({ loading: false });
            this.onSave(this.state.dialogData);
            this.onClose();

        } else {
            this.setState({ loading: false });
            this.setState({ alertText: formatError(result) });
        }
    };

    handleDialogClose = (event, name) => {
        // what to do if we have data changed and dialog is closing

    };


    handleDialogValueChanged(id, value) {

        let data = this.state.dialogData;

        data[id] = value;
        this.setState({ dialogData: data });
    }


    render() {

        let data = this.state.dialogData || {};
        let editorFields = this.Head.filter(f => !f.disableEditor);

        return (
            <>
                <Dialog open={this.state.dialogOpen} onClose={this.handleClose}>
                    <DialogTitle>{data.id ? i18n.t(`pages.${this.Name}.edit`) : i18n.t(`pages.${this.Name}.add`)}</DialogTitle>
                    <DialogContent>
                        {
                            this.state.alertText && (
                                <Alert key="alert" severity="error">
                                    <AlertTitle>{i18n.t('text.error')}</AlertTitle>
                                    {this.state.alertText}
                                </Alert>
                            )
                        }

                        {
                            editorFields.map((field) => {
                                let Field = Fields[field.cell];
                                if (!Field) Field = Fields.Text; // fallback to text
                                return (<Field
                                    key={field.id}
                                    field={field}
                                    value={data[field.id]}
                                    data={data}
                                    cache={this.cache}
                                    onChange={(id, value) => this.handleDialogValueChanged(id, value)}
                                />);
                            })
                        }

                    </DialogContent>
                    <DialogActions>
                        <Button disabled={this.state.loading} onClick={(event) => this.handleDialogCancel(event)}>{i18n.t("buttons.cancel")}</Button>
                        <LoadingButton loading={this.state.loading} onClick={(event) => this.handleDialogSave(event)}>{i18n.t("buttons.save")}</LoadingButton>
                    </DialogActions>
                </Dialog>

            </>
        );
    }

}
