import React from 'react';

import { Typography, Grid, Card, Stack, Collapse } from '@mui/material';
import {
    ExpandCircleDown,
    ArrowUpward,
    ArrowDownward,
    Warning
} from '@mui/icons-material';

import TestValueWithRange from 'components/test-results/TestValueWithRange';

import RichText from '../richtext';
import { ExpandMore } from './styles';
import i18 from '../../i18';
import {
    parseRichTextValue,
    parseValue
} from '../../utils/localizationParsers';

export default class TestResultSingleRow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            result: props.result,
            expanded: false
        };
    }

    render() {
        const currentLanguage = i18.language || 'en';

        let result = this.state.result;
        let isHigh = result.value > result.rangeMax;
        let isLow = result.value !== null && result.value < result.rangeMin;

        let noData = result.value === null;

        //let color = noData ? "warning" : (isHigh || isLow ? "error" : "success");

        const parsedLongName = result?.referenceInfo
            ? parseValue(result.referenceInfo.longName)
            : null;
        const parsedShortCode = result ? parseValue(result.shortCode) : null;
        const nameTestElement =
            parsedLongName[currentLanguage] || parsedShortCode[currentLanguage];

        const parsedDescriptionText = result?.referenceInfo
            ? parseRichTextValue(result.referenceInfo.descriptionText)
            : null;
        const parsedHighLevelText = result?.referenceInfo
            ? parseRichTextValue(result.referenceInfo.highLevelText)
            : null;
        const parsedLowLevelText = result?.referenceInfo
            ? parseRichTextValue(result.referenceInfo.lowLevelText)
            : null;
        const parsedHowToChangeText = result?.referenceInfo
            ? parseRichTextValue(result.referenceInfo.howToChangeText)
            : null;

        const descriptionText = parsedDescriptionText[currentLanguage];
        const highLevelText = parsedHighLevelText[currentLanguage];
        const lowLevelText = parsedLowLevelText[currentLanguage];
        const howToChangeText = parsedHowToChangeText[currentLanguage];

        return (
            <Card
                key={result.id}
                sx={{
                    py: 2,
                    px: 2,
                    my: 2,
                    boxShadow: 1,
                    color: (theme) => theme.palette.grey[700], // theme.palette[color].darker,
                    bgcolor: (theme) => theme.palette.grey[200] // theme.palette[color].lighter,
                }}
            >
                <Grid container spacing={0}>
                    <Grid item xs={12} sm={3} md={4}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ marginTop: 2 }}
                        >
                            <Typography fontSize={18} fontWeight="bold" noWrap>
                                {nameTestElement}
                            </Typography>
                            {noData && (
                                <Warning sx={{ width: 20, height: 20 }} />
                            )}
                            {isHigh && (
                                <ArrowUpward sx={{ width: 20, height: 20 }} />
                            )}
                            {isLow && (
                                <ArrowDownward sx={{ width: 20, height: 20 }} />
                            )}
                        </Stack>
                    </Grid>

                    <Grid item xs={10} sm={8} md={6}>
                        <TestValueWithRange
                            rangeMin={result.rangeMin}
                            rangeMax={result.rangeMax}
                            dimension={result.dimension}
                            value={result.value}
                        />
                    </Grid>

                    <Grid item xs={2} sm={1} md={2}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{ marginTop: 1 }}
                        >
                            <Typography fontSize={18} sx={{ opacity: 0.72 }}>
                                {/*{ !result.value ? i18n.t("text.na") : result.value + " " + result.dimension}*/}
                            </Typography>
                            <ExpandMore
                                expand={this.state.expanded}
                                onClick={() =>
                                    this.setState({
                                        expanded: !this.state.expanded
                                    })
                                }
                                aria-expanded={this.state.expanded}
                            >
                                <ExpandCircleDown />
                            </ExpandMore>
                        </Stack>
                    </Grid>
                </Grid>

                <Collapse in={this.state.expanded}>
                    <RichText disableEditor={true} value={descriptionText} />
                    <RichText disableEditor={true} value={highLevelText} />
                    <RichText disableEditor={true} value={lowLevelText} />
                    <RichText disableEditor={true} value={howToChangeText} />
                </Collapse>
            </Card>
        );
    }
}
