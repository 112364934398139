import React from 'react';
// @mui
import { TableCell } from '@mui/material';
import i18 from '../../i18';
import i18n from '../../i18';
import config from "../../config";

// Text, Number, Items editorCells are similar
export default class GridTranslatableCell extends React.Component {
    render() {
        // props: value, id, field
        const currentLanguage = i18.language || config.language || 'en';
        const cellType = this.props.field.type; // richText
        let parsedValue;
        try {
            parsedValue = JSON.parse(this.props.value);

            if (
                typeof parsedValue !== 'object' ||
                parsedValue === null ||
                Array.isArray(parsedValue)
            ) {
                throw new Error('Invalid format, expected object');
            }
        } catch (e) {
            parsedValue = {
                en: this.props.value || '',
                de: this.props.value || ''
            };
        }
        const value = parsedValue[currentLanguage];
        if (cellType === 'richText') {
            const length = value ? value.length : 0;
            return (
                <TableCell key={this.props.field.id} align="left">
                    {length > 0 ? length + ' ' + i18n.t('text.bytes') : ''}
                </TableCell>
            );
        }
        return (
            <TableCell key={this.props.field.id} align="left">
                {value}
            </TableCell>
        );
    }
}
