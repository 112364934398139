// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class ReferenceInfoPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "referenceInfo";
        this.Server = server.referenceInfo;
        this.searchFields = ['shortName', 'longName'];
    
        this.Head = [
            { id: 'referenceInfoCategoryId', object: 'referenceInfoCategory', display: 'name', label: 'category', alignRight: false, cell: "Select", from: "referenceInfoCategories", type: 'object' },
            { id: 'currentLanguage', label: 'language', alignRight: false, cell: 'Translator', items: ['en', 'de'], disableGrid: true },
            { id: 'shortName', label: 'shortName', alignRight: false, cell: 'Text' },
            { id: 'longName', label: 'longName', alignRight: false, cell: 'Translatable' },
            { id: 'dimension', label: 'dimension', alignRight: false, cell: 'Text' },
            { id: 'lowLevelText', label: 'lowLevelText', alignRight: false, cell: 'Translatable', type: 'richText' },
            { id: 'highLevelText', label: 'highLevelText', alignRight: false, cell: 'Translatable', type: 'richText' },
            { id: 'howToChangeText', label: 'howToChangeText', alignRight: false, cell: 'Translatable', type: 'richText' },
            { id: 'position', label: 'position', alignRight: false, cell: 'Text' },
            { id: 'descriptionText', label: 'descriptionText', alignRight: false, cell: 'Translatable', type: 'richText' },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];

    }
  
}
