import { useState, useEffect } from 'react';
import { Button, Snackbar } from '@mui/material';

export default function ServiceWorkerHandler() {
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

    useEffect(() => {
        const handleUpdateMessage = async (event) => {
            if (event.data.meta === 'workbox-broadcast-update') {
                const { cacheName, updatedURL } = event.data.payload;

                // Do something with cacheName and updatedURL.
                // For example, get the cached content and update
                // the content on the page.
                const cache = await caches.open(cacheName);
                const updatedResponse = await cache.match(updatedURL);
                const updatedText = await updatedResponse.text();
                console.log('Updated content:', updatedText);
                setIsUpdateModalOpen(true);
            }
        };

        navigator.serviceWorker.addEventListener(
            'message',
            handleUpdateMessage
        );

        return () => {
            navigator.serviceWorker.removeEventListener(
                'message',
                handleUpdateMessage
            );
        };
    }, []);

    const confirmUpdate = () => {
        window.location.reload();
        setIsUpdateModalOpen(false);
    };

    const cancelUpdate = () => {
        setIsUpdateModalOpen(false);
    };
    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isUpdateModalOpen}
            message="A new version is available. Do you want to update?"
            action={
                <>
                    <Button
                        color="primary"
                        size="small"
                        onClick={confirmUpdate}
                    >
                        Update
                    </Button>
                    <Button
                        color="secondary"
                        size="small"
                        onClick={cancelUpdate}
                    >
                        Cancel
                    </Button>
                </>
            }
        />
    );
}
