import PropTypes from 'prop-types';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment
} from '@mui/material';
// component
import Iconify from 'components/iconify';
import FilterItemsAbstract from '../../pages/FilterAbstract/FilterItemsAbstract';
import i18n from '../../i18';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    "width": 240,
    "transition": theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': {
        width: 320,
        boxShadow: theme.customShadows.z8
    },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`
    }
}));

// ----------------------------------------------------------------------

GridListToolbar.propTypes = {
    numSelected: PropTypes.number,
    onDelete: PropTypes.func,
    handleSearchData: PropTypes.func,
    searchValue: PropTypes.string,
    handleFilterItems: PropTypes.func,
    Head: PropTypes.array,
    data: PropTypes.array,
    exportButton: PropTypes.bool,
    name: PropTypes.string
};

export default function GridListToolbar({
    Head,
    data,
    numSelected,
    handleSearchData,
    searchValue,
    handleFilterItems,
    onDelete,
    exportButton,
    exportDataToCsv,
    name
}) {
    const handleCloseSearch = () => {
        handleSearchData('');
    };

    const renderExportButton = () => {
        return (
            <Tooltip title={i18n.t('buttons.exportCsv')}>
                <IconButton onClick={exportDataToCsv}>
                    <FileDownloadIcon />
                </IconButton>
            </Tooltip>
        );
    };

    return (
        <StyledRoot
            sx={{
                ...(numSelected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            {numSelected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {numSelected} selected
                </Typography>
            ) : (
                <>
                    <StyledSearch
                        value={searchValue}
                        onChange={(event) =>
                            handleSearchData(event.target.value)
                        }
                        placeholder={i18n.t('text.search')}
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify
                                    icon="eva:search-fill"
                                    sx={{
                                        color: 'text.disabled',
                                        width: 20,
                                        height: 20
                                    }}
                                />
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={handleCloseSearch}
                                    disabled={!searchValue}
                                >
                                    <Iconify icon="eva:close-fill" />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FilterItemsAbstract
                        Head={Head}
                        handleFilterItems={handleFilterItems}
                    />
                    {exportButton && renderExportButton()}
                </>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Delete" onClick={onDelete}>
                    <IconButton>
                        <Iconify icon="eva:trash-2-fill" />
                    </IconButton>
                </Tooltip>
            ) : (
                <></>
            )}
        </StyledRoot>
    );
}
