// components
import GridPageAbstract from '../GridPageAbstract';
//server
import server from 'server';

export default class ResellerTestsPage extends GridPageAbstract {

    constructor(props) {
        super(props);

        this.Name = "resellerTests";
        this.Server = server.resellerTests;
    
        this.Head = [
            { id: 'resellerId', object: 'reseller', display: 'workCompany', label: 'reseller', alignRight: false, cell: 'Select', from: 'therapists', type: 'object' },
            { id: 'serialFrom', label: 'serialFrom', alignRight: false, cell: 'Text' },
            { id: 'serialTo', label: 'serialTo', alignRight: false, cell: 'Text' },
            { id: 'dateModified', label: 'dateModified', alignRight: false, cell: 'Date', disableEditor: true },
            { id: 'dateCreated', label: 'dateCreated', alignRight: false, cell: 'Date', disableEditor: true },
        ];

    }
  
}
