import GridTextCell from "./GridTextCell";
import GridDateCell from "./GridDateCell";
import GridItemsCell from "./GridItemsCell";
import GridSelectCell from "./GridSelectCell";
import GridStatusCell from "./GridStatusCell";
import GridTestDataCell from "./GridTestDataCell";
import GridRichTextCell from "./GridRichTextCell";
import EditorSelectCell from "./EditorSelectCell";
import GridLabelCell from './GridLabelCell';
import GridBooleanCell from './GridBooleanCell';
import GridTranslatableCell from "./GridTranslatableCell";

const Cells = {
    Text: GridTextCell,
    Date: GridDateCell,
    Items: GridItemsCell,
    Select: GridSelectCell,
    Status: GridStatusCell,
    Label: GridLabelCell,
    Boolean: GridBooleanCell,
    TestData: GridTestDataCell,
    RichText: GridRichTextCell,
    EditorSelect: EditorSelectCell,
    Translatable: GridTranslatableCell
};

export default Cells;
