import { MenuItem } from '@mui/material';

import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PreviewIcon from '@mui/icons-material/Preview';

// components
import GridPageAbstract from '../GridPageAbstract';
import i18n from 'i18';
//server
import server from 'server';
import AddAnotherUserTestDialog from '../dialogs/AddAnotherUserTestDialog';
import { formatError } from '../../utils/formatError';
import { downloadPdfFile } from '../../server/dataService';

export default class TestsPage extends GridPageAbstract {
    constructor(props) {
        super(props);

        this.Name = 'tests';
        this.Server = server.tests;
        this.exportButton = true;
        this.searchFields = ['serial', 'user.firstName', 'user.lastName'];

        this.CanUpdate = [];
        this.CanDelete = ['admin'];

        this.Head = [
            {
                id: 'userId',
                object: 'user',
                display: 'firstName+lastName',
                label: 'user',
                alignRight: false,
                cell: 'Select',
                type: 'object',
                from: 'users'
            },
            {
                id: 'resellerId',
                object: 'reseller',
                display: 'workCompany',
                label: 'reseller',
                alignRight: false,
                cell: 'Select',
                type: 'object',
                from: 'therapists',
                disableEditor: true
            },
            { id: 'serial', label: 'serial', alignRight: false, cell: 'Text' },
            {
                id: 'status',
                label: 'status',
                alignRight: false,
                cell: 'Label',
                disableEditor: true
            },
            {
                id: 'dateModified',
                label: 'dateModified',
                alignRight: false,
                cell: 'Date',
                disableEditor: true
            },
            {
                id: 'dateCreated',
                label: 'dateCreated',
                alignRight: false,
                cell: 'Date',
                disableEditor: true
            }
        ];
    }

    renderDialog() {
        return (
            <AddAnotherUserTestDialog
                dialogOpen={this.state.dialogOpen}
                onSave={() => this.handleDialogOnSave()}
                onClose={() => this.handleDialogOnClose()}
                items={this.state.cache.users}
            />
        );
    }

    async handleDownloadAsPdf() {
        let item = this.List.filter(
            (it) => it.id === this.state.menuClickItemId
        ).at(0);
        if (item.status === 'result_received') {
            try {
                await downloadPdfFile(
                    this.state.menuClickItemId,
                    this.Server,
                    (alertText) =>
                        this.setState({ snackbarOpen: true, alertText })
                );
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
    }

    handleShowDetails() {
        let item = this.List.filter(
            (it) => it.id === this.state.menuClickItemId
        ).at(0);
        if (item.status === 'result_received') {
            this.setState({
                navigateLink:
                    '/app/user-test-results/' + this.state.menuClickItemId
            });
        }
    }

    renderCustomMenuItems() {
        //TODO: add status check here to disable menu items
        const showDetails =
            this.List.find((item) => item.id === this.state.menuClickItemId)
                ?.status === 'result_received';
        return (
            showDetails && [
                <MenuItem onClick={() => this.handleDownloadAsPdf()}>
                    <PictureAsPdfIcon sx={{ mr: 2 }} />
                    {i18n.t('buttons.downloadAsPdf')}
                </MenuItem>,

                <MenuItem onClick={() => this.handleShowDetails()}>
                    <PreviewIcon sx={{ mr: 2 }} />
                    {i18n.t('buttons.showDetails')}
                </MenuItem>
            ]
        );
    }
    handleExportDataToCsv = async () => {
        try {
            const sort = this.Server.getSortField(
                this.state.sortConfig.field,
                this.state.sortConfig.direction
            );

            const result = await this.Server.export(
                this.state.filterRequest,
                sort,
                {
                    page: 0,
                    pageSize: this.state.totalCount || 1000
                }
            );
            const url = window.URL.createObjectURL(result);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${this.Name}.csv`;
            a.click();
        } catch (error) {
            this.setState({
                snackbarOpen: true,
                alertText: formatError(error)
            });
        }
    };
}
